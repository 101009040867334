<template>
  <div class="px-7 py-7">
    <template>
      <v-row>
        <v-col cols="6" md="3" class="pl-3">
          <v-autocomplete
            label="Filter By Year"
            outlined
            dense
            v-model="yearFilter"
            :items="yearList"
            class="formFields"
            color="#7253CF"
            @change="getGameData()"
            :disabled="loading"
            v-if="
              this.adminAccess === 'Super Admin' ||
              this.adminAccess == 'Sales Representative' ||
              this.adminAccess == 'Event Support Staff'
            "
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" md="3">
          <v-text-field
            hide-details=""
            outlined
            :disabled="loading"
            height="43px"
            dense
            v-model="search"
            label="Search by Organization Name, City, Sales Rep, Event Support"
            append-icon="mdi-magnify"
            type="text"
            color="#38227A"
            class="search-input"
            style="margin: 5px"
            v-if="
              this.adminAccess === 'Super Admin' ||
              this.adminAccess == 'Sales Representative' ||
              this.adminAccess == 'Event Support Staff'
            "
          >
          </v-text-field>
        </v-col>
        <!-- v-if="$vuetify.breakpoint.smAndUp"-->
        <v-col
          class="d-flex justify-end text-right"
          cols="4"
          md="3"
          v-if="
            ($vuetify.breakpoint.smAndUp &&
              this.adminAccess === 'Super Admin') ||
            this.adminAccess == 'Sales Representative' ||
            this.adminAccess == 'Event Support Staff'
          "
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="285px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="loading"
                v-model="dateRangeText"
                label="Filter by Date"
                prepend-inner-icon="mdi-calendar-blank"
                clearable
                v-bind="attrs"
                outlined
                v-on="on"
                dense
                height="43px"
                color="#2C1963"
                style="font-size: 15px; margin: 5px"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              no-title
              @input="filterDate(dates)"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" md="3">
          <v-btn
            text
            style="
              border: 1px solid #38227a;
              border-radius: 10px;
              margin: 5px;
              width: 130px;
            "
            class="px-11 mr-5 text-capitalize"
            height="45px"
            @click="visitBulkImportHandler"
            v-if="this.adminAccess === 'Super Admin'"
          >
            <span style="color: #38227a">Bulk import</span>
          </v-btn>
          <v-btn
            color="#38227A"
            dark
            class="px-11 text-capitalize"
            style="border-radius: 10px; margin: 5px; width: 130px"
            height="45px"
            @click="toggleAddEditViewGameModal({ show: true, type: 'add' })"
            v-if="
              this.adminAccess === 'Super Admin' ||
              this.adminAccess == 'Sales Representative' ||
              this.adminAccess == 'Event Support Staff'
            "
          >
            <span>Add Game</span>
          </v-btn>
        </v-col>
        <v-col
          v-if="
            this.adminAccess == 'Sales Representative' ||
            this.adminAccess == 'Event Support Staff'
          "
        >
          <v-btn
            color="#38227A"
            dark
            @click="ShowAll()"
            class="text-capitalize px-5"
            height="45px"
            style="border-radius: 10px; position: unset; margin-left: 20px"
            v-if="!currentState"
          >
            Show All
          </v-btn>
          <v-btn
            color="#38227A"
            dark
            @click="myEvents()"
            class="text-capitalize px-5"
            height="45px"
            style="border-radius: 10px; position: unset"
            v-if="currentState"
          >
            My events
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
      :items-per-page="page_info.detail_count"
      :server-items-length="page_info.detail_count"
      :options.sync="dataOptions"
    >
      <!-- items template -->
      <template v-slot:item="props">
        <tr>
          <td class="text-center py-2">
            <div class="pl-2 text-center">
              <div class="textHeading">
                <a
                  style="color: black"
                  @click="visitGamePanelHandler(props.item)"
                >
                  {{ props.item.city.toUpperCase() }},
                  {{ props.item.state_name }}
                </a>
              </div>
              <div class="textHeading">
                {{ props.item.organization }}
              </div>
              <div>
                <v-chip small :class="`${props.item.status.toUpperCase()}`">
                  {{ props.item.status.toUpperCase() }}
                </v-chip>
              </div>
            </div>
          </td>
          <td class="text-center">
            <div v-if="props.item.date != ''">
              {{ props.item.game_date_str_2 }}
            </div>
            <div v-else>null</div>
          </td>
          <td class="text-center">
            <div v-if="props.item.start_time != ''">
              {{ converTime(props.item.start_time) }}
            </div>
            <div v-else>null</div>
          </td>

          <td class="text-center">
            <v-btn
              color="#38227A"
              width="65%"
              dark
              class="text-capitalize px-7 mr-5"
              @click="visitGamePanelHandler(props.item)"
              height="45px"
              style="border-radius: 10px; margin: 20px 5px; font-size: 15px"
            >
              <span>Go To Dashboard</span>
            </v-btn>

            <!-- <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        class="actionColumn-btn"
                        color="#F2F2F2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="25" color="#6B6B6B"
                          >mdi-dots-horizontal</v-icon
                        >
                      </v-chip>
                    </template>
                    <v-list class="menu-list moreOptionsStyle py-0">
                      <v-list-item-group class="py-0">
                        <v-list-item
                          style="min-height: 40px"

                        >
                          <v-icon left size="18">mdi-pencil</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Edit Campaign
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          style="
                            min-height: 40px;
                            border-top: 1px solid #d2d2d2;
                          "
                        >
                          <v-icon size="18" left>mdi-delete</v-icon>
                          <v-list-item-title style="font-size: 14px">
                            Delete Campaign
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu> -->
          </td>
        </tr>
      </template>
      <!-- items template -->
      <template v-slot:footer>
        <div>
          <v-divider></v-divider>
          <v-row no-gutters class="pt-6 px-2">
            <v-col
              class="mb-4"
              align-self="center"
              cols="12"
              xs="12"
              sm="6"
              md="4"
            >
              <div
                class="justify-center justify-md-start d-flex px-5 tableHeader-text"
              >
                <div class="alignSelf-center mr-3">Show</div>
                <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                  <v-select
                    class=""
                    :items="itemsPerPageList"
                    hide-details
                    v-model="page_info.detail_count"
                    dense
                  ></v-select>
                </div>

                <div class="alignSelf-center">
                  {{ page_info.page_number }}
                  of
                  {{ page_info.total_page }}
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" md="4">
              <div>
                <v-pagination
                  :length="page_info.total_page"
                  v-model="page_info.page_number"
                  :total-visible="5"
                  color="#D30024"
                  class="pagination"
                >
                </v-pagination>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <game-modal @reload="getGameData"></game-modal>
    <delete-game-modal @reload="getGameData"></delete-game-modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import {
  //   DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  //   DATA,
} from "@/constants/APIKeys";
import { ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import {
  ADMIN_API_GAME_MANAGEMENT_LIST,
  API_GET_FISCAL_YEAR_RANGE,
} from "@/constants/APIUrls";
import _ from "lodash";
export default {
  name: "GameTable",
  components: {
    GameModal: () => import("../AddEditGameModal/AddEditGameModal"),
    DeleteGameModal: () =>
      import("../DeleteGameConfirmation/DeleteGameConfirmation.vue"),
  },
  data() {
    return {
      yearList: [],
      yearFilter: "",
      showFlag: false,
      currentState: false,
      adminAccess: localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      items: [],
      dates: [],
      menu: false,
      start_date: "",
      end_date: "",
      loading: false,
      [PAGE_INFO]: {
        [PAGE_NUMBER]: 1,
        [TOTAL_PAGE]: 5,
        [ITEMS_PER_PAGE]: 10,
      },
      itemsPerPageList: [10, 15, 20, 30, 40, 50, 100],
      [SEARCH]: "",
      [SORTING_KEY]: "",
      headers: [
        {
          sortable: true,
          text: "Game Info",
          align: "center",
          value: "city",
          class: ["tableHeader-text", "tableHeader-bg"],
        },

        {
          sortable: true,
          text: "Game Date",
          align: "center",
          value: "date",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Game Time",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },

        {
          sortable: false,
          text: "Actions",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
    };
  },
  computed: {
    dateRangeText: {
      get() {
        if (this.dates) {
          if (this.dates.length > 1) {
            let date = [
              moment(this.dates[0]).format("MM-DD-YYYY"),
              moment(this.dates[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates.join("  -  ");
      },
      set(newDates) {
        this.dates = newDates || [];
        return this.dates.length > 1 ? this.dates.join("  -  ") : "";
      },
    },
  },
  watch: {
    dates: function () {
      if (this.dates.length !== 1) {
        this.debounceGetGameManagementData();
      }
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.sorting_key = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getGameData();
      } else {
        this.sorting_key = "";
        this.getGameData();
      }
    },
    search() {
      this.debounceGetGameManagementData();
    },
    "page_info.page_number": function () {
      this.getGameData();
    },
    "page_info.detail_count": function () {
      this.getGameData();
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewGameModal: "gameManagement/toggleModal",
      toggleDeleteGameModal: "gameManagement/toggleDeleteModal",
      showToast: "snackBar/showToast",
    }),

    visitBulkImportHandler() {
      this.$root.$refs.GameManagement.changeCondition(false);
    },
    visitGamePanelHandler(game) {
      if (this.adminAccess == "game") {
        const { id } = game;
        console.log(id);
        let routerData = this.$router.resolve({
          name: ROUTER_URL.gamePanel.children.gamehostassembly.name,
          query: { game_id: id },
        });
        window.open(routerData.href, "_blank");
      } else {
        const { id } = game;
        console.log(id);
        let routerData = this.$router.resolve({
          name: ROUTER_URL.gamePanel.children.dashboard.name,
          query: { game_id: id },
        });
        window.open(routerData.href, "_blank");
      }
    },
    visitCalendarPanelHandler(game) {
      const { id } = game;
      console.log(id);
      let routerData = this.$router.resolve({
        name: ROUTER_URL.gamePanel.children.gamecalendar.name,
        query: { game_id: id },
      });
      window.open(routerData.href, "_blank");
    },
    filterDate(dates) {
      if (dates.length > 1) {
        this.$refs.menu.save(dates);
      }
    },
    getGameData() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.items = res.data.game_list;
        this.page_info[PAGE_NUMBER] = res.data[PAGE_INFO][PAGE_NUMBER];
        //  alert(res.data[PAGE_INFO][PAGE_NUMBER])
        this.page_info[TOTAL_PAGE] = res.data[PAGE_INFO][TOTAL_PAGE];
        this.page_info[ITEMS_PER_PAGE] = res.data[PAGE_INFO][ITEMS_PER_PAGE];
        // alert(res.data[PAGE_INFO][ITEMS_PER_PAGE])
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData[PAGE_NUMBER] = this.page_info[PAGE_NUMBER];
      formData[ITEMS_PER_PAGE] = this.page_info.detail_count;
      formData[SORTING_KEY] = this.sorting_key;
      formData[SEARCH] = this.search;
      if (this.showFlag == true) {
        formData.show_all = "show_all";
      }
      if (this.dates) {
        if (this.dates.length == 2) {
          let filter = {
            start_date: moment(this.dates[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates[1]).format("MM-DD-YYYY"),
          };
          formData["filter"] = filter;
        } else {
          formData["filter"] = {};
        }
        if (this.yearFilter) {
          formData.fiscal_year = this.yearFilter;
        }
      }
      Axios.request_GET(
        ADMIN_API_GAME_MANAGEMENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    ShowAll() {
      this.showFlag = true;
      this.currentState = true;
      this.getGameData();
    },
    myEvents() {
      location.reload();
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },

    resendInvitation(item) {
      const successHandler = () => {
        this.showToast({
          message: "Invitation sent successfully.",
          color: "s",
        });
      };

      const failureHandler = () => {};
      let formData = {};
      formData["id"] = item.id;
      Axios.request_GET(
        "/game/resend_email/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getYearList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.yearList = data.year_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_GET_FISCAL_YEAR_RANGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
  mounted() {
    this.debounceGetGameManagementData = _.debounce(this.getGameData, 500);
    //  this.getGameData();
    this.getYearList();
  },
  created() {},
};
</script>
<style scoped>
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
.alignSelf-center {
  align-self: center;
}
* {
  font-family: Lato;
}
.v-btn >>> span {
  letter-spacing: 0px;
  font-weight: 600;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}
.textHeading {
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
}
.textHeadinglabels {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
